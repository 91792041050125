// src/services/articleService.js
import axiosInstance from '../api/axiosInstance';

const getAllArticles = async () => {
  try {
    const response = await axiosInstance.get('/articles');
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export default {
  getAllArticles,
};
