import logo from './logo.svg';
import './App.css';
import wechat from './images/wechat.png';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <div className="main">
        <h1>Meta20-STUDY</h1>
        <p>我始终相信，高级的人生，是一边不妥协一边在坚持</p>
        <p>人间烟火，总有一盏灯是为你而亮的</p>
        <p>I Always Believe That You Are The Niubier </p>
      </div>
      <div className="gohome blur-out-expand" onclick="gohome()">
        进入首页
      </div>
      <div className="contact">

        <div className='wechat'>
          <img src={wechat} alt="" title="WeChat" />
        </div>
      </div>
    </div>
  );
}

export default App;
