// src/api/axiosInstance.js
import axios from '../../node_modules/axios/lib/axios.js';

const axiosInstance = axios.create({
  baseURL: 'http://localhost:5000/api', // 后端API的基本URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
